import React, { useState, useEffect } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import faqData from '../../assets/json/faqData.json';
import { FiPlusCircle } from 'react-icons/fi';
import { AiOutlineMinusCircle } from 'react-icons/ai';

const QuestionsDropdownPage = () => {
  const { pathname } = useLocation();
  const [expand, setExpand] = useState(false);
  useEffect(() => {
    if (pathname && pathname.includes('business-faq')) {
      setExpand(true);
    }
  }, [pathname]);
  useEffect(() => {
    setExpand(true);
  }, []);
  const [openQuestionIndex, setOpenQuestionIndex] = useState<number | null>(
    null
  );

  return (
    <div className='mt-6'>
      <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold'>
        Questions?
      </h2>
      <div className='primary-shadow px-4 md:px-8 pt-6 rounded-b-lg border border-gray-200 '>
        <p className='lg:py-2 font-medium text-lg'>
          If you have questions, our FAQ section is here to help. Browse through
          to find detailed answers and helpful information on a variety of
          topics. We hope you'll find everything you need to enhance your
          experience.
        </p>
        <div className='mt-6 mx-0 md:mx-auto '>
          {(expand
            ? faqData
            : faqData.filter(question => [20, 27, 28].includes(question.id))
          ).map((question, index) => (
            <Quest
              key={question.id}
              el={question}
              isOpen={openQuestionIndex === index}
              toggle={() =>
                setOpenQuestionIndex(openQuestionIndex === index ? null : index)
              }
            />
          ))}
        </div>

        {!expand && (
          <Link to={'/business-faq'}>
            <div className='flex flex-row justify-end my-8 gap-2 items-center'>
              <p className='text-primary font-semibold hover:underline'>
                Read more{' '}
              </p>
              <FaExternalLinkAlt className='text-primary font-semibold' />
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

interface QuestProps {
  el: {
    id: number;
    question: string;
    answer: string;
  };
  isOpen: boolean;
  toggle: () => void;
}

// Question component
export const Quest: React.FC<QuestProps> = ({ el, isOpen, toggle }) => {
  return (
    <>
      <hr className='w-full' />
      <div
        className='flex flex-row justify-between items-center py-4 cursor-pointer'
        onClick={toggle}
        key={el.id}
      >
        <p className='text-[20px] md:text-xl py-4 md:py-5 pr-4 md:pr-0 text-[#5A5548] font-medium md:font-[400]'>
          {el.question}
        </p>
        <p
          className='text-[1.65rem] md:text-[2rem] text-[#000000]'
          // style={
          //   isOpen
          //     ? { padding: '0.125rem 0.25rem 0.25rem' }
          //     : { padding: '0.125rem 0.25rem' }
          // }
        >
          {isOpen ? <AiOutlineMinusCircle /> : <FiPlusCircle />}
        </p>
      </div>
      {isOpen && <p className='text-[#5A5548] mb-4 text-base'>{el.answer}</p>}
      <hr className='w-full' />
    </>
  );
};

export default QuestionsDropdownPage;
