import React from 'react';

// Define the type for a news item
export interface NewsItem {
  id: number;
  title: string;
  content: string;
  date: string;
}

// Dummy data array of news items
export const newsData: NewsItem[] = [
  {
    id: 1,
    title: 'App Enhancements',
    content:
      'The latest release of the app is packed with new features and fixes. One such enhancement is the clear distinction between “Card linked” offers and “Click” offers. Members will now see the relevant icon next to each listed merchant, making it easier for them to earn Cashback at their favourite stores. We would love to hear your feedback and your members’ feedback and suggestions. Feel free to write to us any time at programs@saversapp.com',
    date: 'January 24, 2025',
  },
  {
    id: 2,
    title: 'New Merchants Added',
    content:
      'More exciting news! In our ongoing effort to continuously enhance the Savers App experience, we’ve partnered with top US brands to bring even more shopping options to your customers. Some of the brands that have just been added include CVS, Bed Bath & Beyond, Temu, Lego, and Macy’s, to name a few.',
    date: 'January 13, 2025',
  },
  {
    id: 3,
    title: 'New Cashback Features Launched',
    content:
      'Partners can now enjoy an enhanced cashback system that provides better rewards for both customers and merchants. Explore the latest features to boost your sales!',
    date: 'October 10, 2024',
  },
  {
    id: 4,
    title: 'New US Brands Added to Our Merchants List',
    content:
      'Exciting news! We’ve partnered with top US brands to bring even more shopping options to your customers. Stay tuned for exclusive deals and promotions!',
    date: 'October 8, 2024',
  },
  {
    id: 5,
    title: 'Shopping Season Promotions',
    content:
      'Get ready for the upcoming holiday shopping season with exclusive promotions for your customers. Boost sales with seasonal cashback offers!',
    date: 'October 5, 2024',
  },
  {
    id: 6,
    title: 'Marketing Material Updates',
    content:
      'We’ve released new marketing materials to help you promote cashback offers and boost engagement. Download the latest assets from your admin panel now!',
    date: 'October 1, 2024',
  },
];

// Define the News component
const News: React.FC = () => {
  return (
    <div className='py-4 md:py-6'>
      <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold'>
        News
      </h2>
      <div className='border border-gray-200 rounded-b-lg primary-shadow'>
        {newsData.map(item => (
          <div
            key={item.id}
            className='py-4 px-4 md:px-8 border-b last:border-b-0'
          >
            <h3 className='text-xl font-semibold'>{item.title}</h3>
            <p className='text-sm text-gray-600 mb-2'>{item.date}</p>
            <p className='text-gray-700 mb-4'>{item.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;
