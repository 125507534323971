import { useEffect, useRef, useState } from 'react';
import { CgProfile } from 'react-icons/cg';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { TbLogout2 } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import { logoutAndRedirect, setUserDetails } from '../../features/authSlice';

interface Props {
  toggleDrawer: () => void;
  isDrawerOpen: boolean;
}

const Navbar = ({ isDrawerOpen, toggleDrawer }: Props) => {
  const userDetailsStorage = localStorage.getItem('userDetails');
  const [user, setUser] = useState<any>();
  const userStatus = useSelector((state: RootState) => state.auth.status);
  const { userDetails } = useSelector((state: RootState) => state.auth);
  const [statusText, setStatusText] = useState('');
  const [statusColor, setStatusColor] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const brandInfo = useSelector((state: RootState) => state.partner.brandInfo);

  const handleLogout = () => {
    dispatch(logoutAndRedirect(navigate));
  };

  const handleProfileMenu = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setProfileMenuOpen(!profileMenuOpen); // Toggle the menu visibility
  };

  useEffect(() => {
    if (userDetailsStorage) setUser(JSON.parse(userDetailsStorage));
  }, [userDetails, userDetailsStorage]);

  useEffect(() => {
    // Map status to corresponding text and color classes
    const statusMapping = {
      active: { text: 'Active', color: 'text-[#018d31]' },
      inactive: { text: 'Update Brand Info', color: 'text-red-400' },
      inreview: { text: 'In Review', color: 'text-yellow-400' },
      suspended: { text: 'Suspended', color: 'text-red-400' },
      onhold: { text: 'On Hold', color: 'text-orange-400' },
      null: { text: '', color: '#000000' },
    };

    // Get the appropriate status text and color based on userStatus
    const { text, color } = statusMapping[userStatus] || {
      text: 'Unknown',
      color: 'text-gray-400',
    };

    setStatusText(text);
    setStatusColor(color);
  }, [userStatus]);

  useEffect(() => {
    if (userDetailsStorage) {
      setUserDetails(JSON.parse(userDetailsStorage));
      setUser(JSON.parse(userDetailsStorage));
    }
  }, [userDetailsStorage]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setProfileMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav
        className={`grid md:grid-cols-2 place-content-center  h-[100px] w-full bg-primaryBg-50  ${
          isDrawerOpen ? 'lg:px-7 px-3 md:px-5' : 'lg:px-7 px-3 md:px-5'
        } `}
      >
        {/*menu-icon div*/}
        <div className='flex items-center justify-between'>
          <button
            onClick={toggleDrawer}
            className={`${
              isDrawerOpen ? 'hidden' : 'block'
            } text-gray-900 focus:outline-none pr-7`}
          >
            <svg
              className='w-6 h-6'
              fill='#333399'
              stroke='#333399'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M4 6h16M4 12h16M4 18h16'
              ></path>
            </svg>
          </button>

          <div className='hidden md:block w-full'>
            <div className='flex flex-row md:gap-[4rem] xmd:gap-[7rem] whitespace-nowrap justify-between'>
              <h4 className='md:text-[0.75rem] xmd:text-[1.15rem] pl-4 font-medium'>
                {brandInfo?.orgDisplayName || user?.partner?.name}
              </h4>
              {statusText && (
                <h4 className='md:text-[0.75rem] xmd:text-[1.15rem]'>
                  Program Status:{' '}
                  <span className={`${statusColor} mt-[1px] font-semibold`}>
                    {statusText}
                  </span>
                </h4>
              )}
            </div>
          </div>
          <div className='block md:hidden'>
            <div className='flex flex-row gap-10 md:gap-[7rem]'>
              <h4 className='md:text-[0.75rem] xmd:text-[1.25rem]'>
                {user?.partner?.name}
              </h4>
              {statusText && (
                <h4 className='md:text-[0.75rem] xmd:text-[1.25rem]'>
                  Status:{' '}
                  <span className={`${statusColor} mt-[1px] font-semibold`}>
                    {statusText}
                  </span>
                </h4>
              )}
            </div>
          </div>
        </div>
        <div className='hidden md:flex space-x-1 justify-end items-center'>
          {user && (
            <div
              className='cursor-pointer flex items-center justify-center gap-2'
              onClick={handleProfileMenu}
              ref={dropdownRef}
            >
              <div className='hidden md:block whitespace-nowrap text-primary'>
                <p className='font-semibold text-[1.15rem] pt-2 '>
                  {user?.firstName} {user?.lastName}
                </p>
              </div>
              {profileMenuOpen ? (
                <MdKeyboardArrowUp className='mt-2' size={22} color='#333399' />
              ) : (
                <MdKeyboardArrowDown
                  className='mt-2'
                  size={22}
                  color='#333399'
                />
              )}
            </div>
          )}
        </div>
        {/* Profile menu dropdown */}
        {profileMenuOpen && (
          <div className='absolute top-[75px] right-8 bg-white shadow-md rounded-md w-44'>
            <ul className='text-base'>
              <li>
                <button
                  className='w-full flex items-center gap-2 text-left px-4 py-2 hover:bg-gray-100'
                  onClick={() => {
                    navigate('/profile');
                    setProfileMenuOpen(false);
                  }}
                >
                  <CgProfile size={20} color='#333399' />
                  My Profile
                </button>
              </li>
              <li>
                <button
                  className='w-full flex items-center gap-2 text-left px-4 py-2 hover:bg-gray-100 border-t'
                  onClick={handleLogout}
                >
                  <TbLogout2 size={20} color='#333399' />
                  Log Out
                </button>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;
